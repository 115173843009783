import { StepDefinition, IOfflineStepDefinition, ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { IStepSyncProgressJSON,IStepSyncProgressOptions } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { SyncType } from 'o365.pwa.types.ts';
import { ServiceWorkerProgress } from 'o365.pwa.modules.client.steps.ServiceWorkerProgress.ts';

export class ServiceWorkerStepDefinition extends StepDefinition implements IOfflineStepDefinition<ServiceWorkerProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    
    generateStepProgress(options?: IStepSyncProgressJSON | IStepSyncProgressOptions, syncType?: SyncType): ServiceWorkerProgress {
        throw new Error('Method not implemented.');
    }
    
    syncOffline(options: ISyncOptions<ServiceWorkerProgress>): Promise<void> {
        throw new Error('Method not implemented.');
    }
}
